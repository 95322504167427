<template>
<div style="width: 100%;height: 100%" v-cloak>
    <div class="header">
        <img src="../assets/ic_public_back.svg" alt="">
        <span v-text="playMusic.title"></span>
    </div>
    <div class="content">
        <div class="round-box">
            <div class="round-2-box">
                <img :src="'http://new.gsleep.com'+playMusic.image" alt="">
            </div>
        </div>
        <div class="content-bottom">
            <div class="music-btn">
                <span class="iconfont icon-xunhuan" @click="changeAudioMode" v-if="playMode == 'list'"></span>
                <span class="iconfont icon-danqu" @click="changeAudioMode" v-if="playMode == 'single'"></span>
                <span class="iconfont icon-suiji" @click="changeAudioMode" v-if="playMode == 'round'"></span>
                <span class="iconfont icon-aixin" @click="loveMusic" v-if="isCollect == 0"></span>
                <span class="iconfont icon-aixin1" @click="loveMusic" v-if="isCollect == 1"></span>
                <div class="countdown">
                    <div>
                        <span v-if="countdownSetTime != 0" v-text="formatSeconds(countdownTime)"></span>
                    </div>
                    <span class="iconfont icon-dignshi" @click="showCountdown"></span>
                </div>
                <span class="iconfont icon-liebiao1" @click="audioListShow"></span>
            </div>
            <div class="progress-bar">
                <span v-text="currentTimes"></span>
                <div class="progress" ref="progressBox" @click="progressClick">
                    <div ref="progress" class="progress-round"></div>
                </div>
                <span v-text="durations"></span>
            </div>
            <div class="music-control">
                <img @click="prevAudio" src="../assets/prev.png" alt="">
                <img @click="pauseAudio" class="play" src="../assets/pause.png" alt="" v-if="playStatus">
                <img @click="playAudio" class="play" src="../assets/play.png" alt="" v-else>
                <img @click="nextAudio" src="../assets/next.png" alt="">
            </div>
        </div>
        <div class="mask" @click="closeAll" v-if="maskStatus"></div>
        <div class="music-box">
            <div class="music-list-box" v-if="showList">
                <div class="music-list-title-box">
                    <div class="music-list-title">
                        <div :class="{'music-list-title-active': playState == 'play'}" @click="this.playState = 'play'">
                            <span>当前系列播放</span>
                        </div>
                        <div :class="{'music-list-title-active': playState == 'collect'}"
                             @click="this.playState = 'collect'">
                            <span>收藏列表播放</span>
                        </div>
                    </div>
                </div>
                <div class="music-list" v-if="playState == 'play'">
                    <div v-for="(item, index) in audioList" :key="index">
                        <div class="list" @click="changeAudio(index)" v-if="index < playPage * 5 && index >= (playPage - 1) * 5">
                            <span v-text="item.title"></span>
                            <img src="../assets/music.png" alt="" v-if="item.id == playMusic.id">
                        </div>
                    </div>
                </div>
                <div class="music-list" v-if="playState == 'collect'">
                    <div v-for="(item, i) in collectList" :key="i">
                        <div class="list" @click="changeAudio(i)" v-if="i < collectPage * 5 && i >= (collectPage - 1) * 5">
                            <span v-text="item.title"></span>
                            <img src="../assets/music.png" alt="" v-if="item.id == playMusic.id">
                        </div>
                    </div>
                </div>
                <div class="music-bottom">
                <span class="iconfont icon-jiantouxia" @click="nextPage()"
                      v-if="playState == 'play' ? playPage*5 < audioList.length : collectPage*5 < collectList.length"></span>
                    <span class="iconfont icon-jiantoushang" @click="prevPage()"
                          v-if="playState == 'play' ? playPage*5 > 5 : collectPage*5 > 5"></span>
                </div>
            </div>
        </div>

        <div class="music-box">
            <div class="music-list-box" v-if="showCountdownList">
                <div class="music-list-title-box center">
                    <div class="music-list-title font16px" @click="cancelCountdown">
                        <span>不开启</span>
                    </div>
                </div>
                <div class="music-list" style="padding: 0">
                    <div class="list" @click="countdown(10)">
                        <span>10分钟</span>
                        <span class="active-down" v-if="countdownSetTime == '10'"
                              v-text="'倒计时'+formatSeconds(countdownTime)"></span>
                    </div>
                    <div class="list" @click="countdown(15)">
                        <span>15分钟</span>
                        <span class="active-down" v-if="countdownSetTime == '15'"
                              v-text="'倒计时'+formatSeconds(countdownTime)"></span>
                    </div>
                    <div class="list" @click="countdown(30)">
                        <span>30分钟</span>
                        <span class="active-down" v-if="countdownSetTime == '30'"
                              v-text="'倒计时'+formatSeconds(countdownTime)"></span>
                    </div>
                    <div class="list" @click="countdown(60)">
                        <span>60分钟</span>
                        <span class="active-down" v-if="countdownSetTime == '60'"
                              v-text="'倒计时'+formatSeconds(countdownTime)"></span>
                    </div>
                    <div class="list" @click="currCountdown()">
                        <span>当前声音播放完后</span>
                        <span class="active-down" v-if="countdownSetTime == 'curr'"
                              v-text="'倒计时'+formatSeconds(countdownTime)"></span>
                    </div>
                </div>
            </div>
        </div>
        <audio class="audio" ref="audio" autoplay
               @ended="overAudio"
               @pause="onPause"
               @play="onPlay"
               @canplay="getDuration"
               @timeupdate="updateTime"
               :src="playMusic.url"></audio>
    </div>
</div>
</template>

<script>
let countdownBox;
export default {
    name: "play",
    data() {
        return {
            playMusic: {
                title: '',
                image: '',
                url: ''
            },
            musicTitle: '音乐名称',
            musicImage: '',
            duration: 0,
            currentTime: 0,
            currentTimes: '00:00',
            durations: '00:00',
            playStatus: false,
            aid: 0,
            audioList: [],
            collectList:[],
            playIndex: 0,
            collectIndex: '',
            maskStatus: false,
            showList: false,
            playMode: 'list', // list 列表播放 single 单曲循环 round 随机
            showCountdownList: false, // 倒计时显示
            countdownSetTime: 0,
            countdownTime: 0,
            playState: 'play', // play 当前系列播放 collect 收藏
            playPage: 1, // 播放页码
            playTotalPage: 1, // 播放总页码
            collectPage: 1, // 收藏播放页码
            collectTotalPage: 1, // 收藏播放页码
            isCollect: 0, // 是否收藏
        }
    },
    watch: {
        playState(val) {
            if (val == 'play') {
                this.getData();
            } else {
                this.getCollect();
            }
        },
        playIndex() {
            this.getCurrMusicCollect()
        }
    },
    computed: {
    },
    methods: {
        currCountdown() {
            this.countdownTime = this.duration - this.currentTime;
            this.countdownSetTime = 'curr';
            countdownBox = setInterval(() => {
                if (this.countdownTime <= 0) {
                    this.onPause();
                    this.cancelCountdown()
                } else {
                    this.countdownTime -= 1;
                }
            }, 1000);
        },
        countdown(min) {
            this.countdownSetTime = min;
            this.countdownTime = min * 60;
            countdownBox = setInterval(() => {
                if (this.countdownTime <= 0) {
                    this.onPause();
                    this.cancelCountdown()
                } else {
                    this.countdownTime -= 1;
                }
            }, 1000);
        },
        cancelCountdown() {
            clearInterval(countdownBox)
            this.countdownTime = 0;
            this.countdownSetTime = 0;
        },
        changeAudioMode() {
            if (this.playMode == 'list') {
                this.playMode = 'single'
            } else if (this.playMode == 'single') {
                this.playMode = 'round'
            } else if (this.playMode == 'round') {
                this.playMode = 'list'
            }
        },
        progressClick(e) {
            let width = this.$refs.progressBox.clientWidth
            let left = this.$refs.progressBox.getBoundingClientRect().left
            var offset = e.pageX;
            let percentage = ((offset - left) / width).toFixed(2);
            this.$refs.audio.currentTime = percentage * this.duration
        },
        changeAudio(index) {
            if (index != this.playIndex) {
                this.playIndex = index;
                this.playAudio()
            }
        },
        audioListShow() {
            this.maskStatus = true;
            this.showList = true;
        },
        showCountdown() {
            this.maskStatus = true;
            this.showCountdownList = true;
        },
        closeAll() {
            this.maskStatus = false;
            this.showList = false;
            this.showCountdownList = false;
        },
        onPause() {
            this.playStatus = false;
        },
        onPlay() {
            this.playStatus = true;
        },
        sum(m, n, curr) {
            let num = Math.floor(Math.random() * Number(m - n + 1) + n);
            if (num == curr) {
                return this.sum(m, n, curr);
            } else {
                return num;
            }
        },
        overAudio() {
            if (this.playStatus) {
                if (this.playMode == 'list') {
                    this.nextAudio();
                } else if (this.playMode == 'single') {
                    this.playAudio();
                } else {
                    let index = this.sum(0, this.audioList.length - 1, this.playIndex);
                    this.playIndex = index;
                }
            }
        },
        getDuration() {
            this.duration = this.$refs.audio.duration;
            this.durations = this.formatSeconds(this.duration);
        },
        updateTime() {
            this.currentTime = this.$refs.audio.currentTime;
            this.currentTimes = this.formatSeconds(parseInt(this.currentTime));
            this.percentage = ((this.currentTime / this.duration).toFixed(4)) * 100;
            this.$refs.progress.style.left = parseInt(this.percentage) - 2.5 + '%';
        },
        nextAudio() {
            if (this.playMode == 'round') {
                let index = this.sum(0, this.audioList.length - 1, this.playIndex);
                this.playIndex = index;
            } else {
                if (this.audioList.length - 1 <= this.playIndex) {
                    this.playIndex = 0;
                } else {
                    this.playIndex += 1;
                }
            }
            this.playAudio();
        },
        prevAudio() {
            if (this.playMode == 'round') {
                let index = this.sum(0, this.audioList.length - 1, this.playIndex);
                this.playIndex = index;
            } else {
                if (this.playIndex <= 0) {
                    this.playIndex = this.audioList.length - 1;
                } else {
                    this.playIndex -= 1;
                }
            }
            this.playAudio();
        },
        playAudio() {
            this.$refs.audio.play()
            if (this.playState == 'play') {
                this.playMusic.id = this.audioList[this.playIndex].id
                this.playMusic.title = this.audioList[this.playIndex].title
                this.playMusic.image = this.audioList[this.playIndex].image
                this.playMusic.url = this.audioList[this.playIndex].audio_url
            } else {
                this.playMusic.id = this.collectList[this.collectIndex].id
                this.playMusic.title = this.collectList[this.collectIndex].title
                this.playMusic.image = this.collectList[this.collectIndex].image
                this.playMusic.url = this.collectList[this.collectIndex].audio_url
            }
            this.playStatus = true
            this.getCurrMusicCollect()
        },
        pauseAudio() {
            this.$refs.audio.pause()
            this.playStatus = false
        },
        formatSeconds(time) {
            let timeStr = '';
            let stringFormat = (i) => {
                return i < 10 ? `0${parseInt(i)}` : `${parseInt(i)}`;
            }
            let minuteTime = 0;
            let secondTime = 0;
            let hourTime = 0;
            if (time < 60) {
                timeStr = `00:${stringFormat(time)}`
            } else if (time >= 60 && time < 3600) {
                minuteTime = parseInt(time / 60);
                secondTime = parseInt(time % 60);
                timeStr = `${stringFormat(minuteTime)}:${stringFormat(secondTime)}`;
            } else if (time >= 3600) {
                let _t = parseInt(time % 3600);
                hourTime = parseInt(time / 3600);
                minuteTime = parseInt(_t / 60);
                secondTime = parseInt(_t % 60);
                timeStr = `${stringFormat(hourTime)}:${stringFormat(minuteTime)}:${stringFormat(secondTime)}`
            }
            return timeStr;
        },
        getData() {
            this.$axios.get('/api/getMusicAlbum?aid=' + this.aid, {
                headers: {'token': this.token},
            }).then((response) => {
                this.audioList = response.data.data;
                this.playMusic.id = this.audioList[this.playIndex].id
                this.playMusic.title = this.audioList[this.playIndex].title
                this.playMusic.image = this.audioList[this.playIndex].image
                this.playMusic.url = this.audioList[this.playIndex].audio_url
                this.getCurrMusicCollect()
            })
        },
        getCollect() {
            this.$axios.get('/api/getCollect', {
                headers: {'token': this.token},
            }).then((response) => {
                this.collectList = response.data.data;
                this.getCurrMusicCollect()
            })
        },
        getCurrMusicCollect() {
            this.$axios.get('/api/isCollectMusic?mid=' + this.audioList[this.playIndex].id, {
                headers: {'token': this.token},
            }).then((response) => {
                this.isCollect = response.data.data
            })
        },
        nextPage() {
            if (this.playState == 'play') {
                this.playPage = this.playPage + 1;
            } else {
                this.collectPage = this.collectPage + 1;
            }
        },
        prevPage() {
            if (this.playState == 'play') {
                this.playPage = this.playPage - 1;
            } else {
                this.collectPage = this.collectPage - 1;
            }
        },
        getUrlKey: function (name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)')
                .exec(location.href) || ['', ""])[1].replace(/\+/g, '%20')) || null
        },
        // 添加播放数量
        addPlayNum() {
            this.$axios.post('/api/addPlayNum', {id: this.aid}, {
                headers: {'token': this.token},
            }).then((response) => {
                this.audioList = response.data.data.rows;
                this.collectTotalPage = response.data.data.page;
            })
        },
        loveMusic() {
            this.$axios.post('/api/loveMusic', {mid: this.audioList[this.playIndex].id}, {
                headers: {'token': this.token},
            }).then((response) => {
                this.isCollect = response.data.data.status
            })
        }
    },
    created() {
        this.aid = this.getUrlKey('aid');
        this.token = this.getUrlKey('token');
        this.$nextTick(()=>{
            this.getData();
            this.addPlayNum();
        })
    },
}
</script>

<style scoped>
.header {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #F2F3F5;
    border-bottom: 1px solid rgb(221, 222, 224);
}

.header img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    object-fit: contain;
}

.header span {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 16px;
    font-weight: 500;
}

.content {
    padding-top: 56px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: rgb(242, 243, 245);
}

.round-box {
    width: 66vw;
    height: 66vw;
    margin: 70px auto;
    background-color: rgb(48, 50, 49);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-animation: haha1 8s linear infinite;
}

.round-2-box {
    width: 41.6vw;
    height: 41.6vw;
    background-color: rgb(89, 91, 90);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*-webkit-animation: haha1 8s linear infinite;*/
}

.round-2-box img {
    width: 50%;
    height: auto;
    object-fit: cover;
}

@-webkit-keyframes haha1 {
    0% {
        -webkit-transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(90deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
    }
    75% {
        -webkit-transform: rotate(270deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.content-bottom {
    position: fixed;
    bottom: 70px;
    left: 0;
    width: 100%;
    height: auto;
}

.music-btn {
    width: 100%;
    height: 55px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.music-btn img {
    width: 28px;
    height: auto;
    object-fit: cover;
}

.music-btn .iconfont {
    font-size: 24px;
    color: #919294;
}

.countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.countdown div {
    position: absolute;
    top: 0;
    width: 28px;
    font-size: 12px;
    text-align: center;
    -webkit-transform: scale(0.9);
}

.progress-bar {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
    box-sizing: border-box;
    margin-top: 5px;
}

.progress-bar span {
    padding: 0 10px;
    font-size: 10px;
    color: rgb(145, 146, 148);
}

.progress {
    width: 100%;
    height: 6px;
    background-color: rgb(145, 146, 148);
    border-radius: 6px;
    position: relative;
}

.progress-round {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: rgb(77, 77, 77);
    top: -3px;
    left: -2.5%;
}

.music-control {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
}

.music-control img {
    width: 30px;
    height: auto;
    object-fit: cover;
}

.play {
    width: 50px !important;
    height: auto;
}

.music-box {
    width: 100%;
    height: auto;
    padding: 16px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
}

.music-list-box {
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    padding: 0 16px;
    box-sizing: border-box;
}

.music-list-title-box {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid rgb(221, 222, 224);
    display: flex;
    justify-content: flex-start;
}

.play-mode img {
    width: 50%;
    height: auto;
    object-fit: cover;
}

.music-list-title {
    width: 100%;
    font-size: 14px;
    color: rgb(77, 77, 77);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.music-list-title div {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.music-list-title-active {
    color: rgb(11, 88, 243);
}

.music-list-title-active:after {
    position: absolute;
    bottom: 10px;
    width: 62%;
    height: 2px;
    content: ' ';
    background-color: rgb(11, 88, 243);
    border-radius: 2px;
}

.music-list {
    width: 100%;
    height: 230px;
    overflow: auto;
    box-sizing: border-box;
}

.list {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid rgb(221, 222, 224);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: rgb(77, 77, 77);
}

.list img {
    width: 15px;
    height: auto;
    object-fit: cover;
}

.music-bottom {
    width: 50%;
    height: 45px;
    line-height: 45px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.center {
    justify-content: center !important;
}

.font16px {
    font-size: 16px;
}

.active-down {
    color: rgb(11, 88, 243);
}

.mask {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
}
</style>
