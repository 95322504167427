<template>
<div class="header">
    <img src="../assets/ic_public_back.svg" alt="">
    <span>睡眠报告</span>
</div>
<div class="content">
    <div class="time-box" v-show="viewState == 1">
        <div class="time-list">
            <div class="year-mon center text-color-tertiary-inverse">
                <div :class="['mon',{'selected': currTimeType == 'mon'}]" @click="this.currTimeType = 'mon'">
                    <span>月</span></div>
                <div :class="['year',{'selected': currTimeType == 'year'}]"
                     @click="this.currTimeType = 'year'"><span>年</span></div>
            </div>
        </div>
        <div class="time-list">
            <div class="times between">
                <span class="iconfont icon-xiangzuo1" @click="prevTime"></span>
                <span v-text="currTimeType == 'mon' ? monTimes : yearTimes"></span>
                <span
                    :class="['iconfont icon-xiangyou1', {'disable': (currTimeType == 'mon' ? monTimes == currMonthTime : yearTimes == currYearTime)}]"
                    @click="nextTime"></span>
            </div>
        </div>
    </div>
    <div class="echarts-box" v-show="viewState == 1">
        <div style="height: 100%">
            <div class="top" v-show="show=='top' && btnState">
                <div class="echarts-list">
                    <div class="echarts-left" ref="sleep1"></div>
                    <div class="echarts-right"><span>入睡困难</span></div>
                </div>
                <div class="echarts-list">
                    <div class="echarts-left" ref="sleep2"></div>
                    <div class="echarts-right"><span>易醒早醒</span></div>
                </div>
                <div class="echarts-list">
                    <div class="echarts-left" ref="sleep3"></div>
                    <div class="echarts-right"><span>半夜醒来</span></div>
                </div>
                <div class="echarts-list">
                    <div class="echarts-left" ref="sleep4"></div>
                    <div class="echarts-right"><span>呼吸不畅</span></div>
                </div>
                <div class="echarts-list">
                    <div class="echarts-left" ref="sleep5"></div>
                    <div class="echarts-right"><span>咳嗽打鼾</span></div>
                </div>
                <div class="echarts-list">
                    <div class="echarts-left" ref="sleep6"></div>
                    <div class="echarts-right"><span>感觉到冷</span></div>
                </div>
                <div class="echarts-list">
                    <div class="echarts-left" ref="sleep7"></div>
                    <div class="echarts-right"><span>感觉到热</span></div>
                </div>
                <div class="echarts-list">
                    <div class="echarts-left" ref="sleep8"></div>
                    <div class="echarts-right"><span>做恶梦</span></div>
                </div>
                <div class="echarts-list">
                    <div class="echarts-left" ref="sleep9"></div>
                    <div class="echarts-right"><span>疼痛不适</span></div>
                </div>
            </div>
            <div class="echarts-list">
                <div class="echarts-bottom-title">
                    <div>
                        <span>记录日常症状</span>
                    </div>
                </div>
                <div :class="['bottom-switch','gray', {'blue':btnState}]" @click="onChangeBtn">
                    <div :class="{'btn': !btnState}"></div>
                    <div :class="{'btn': btnState}"></div>
                </div>
            </div>
            <div class="echarts-list-2">
                <span>每次使用前，系统都会发出信息弹窗进行症状记录。</span>
            </div>
        </div>
    </div>
    <div class="time-box center" v-show="viewState == 2">
        <div class="time-list">
            <div class="year-mon center text-color-tertiary-inverse">
                <div :class="['mon', 'center',{'selected': currTimeType == 'mon'}]"
                     @click="this.currTimeType = 'mon'"><span>周</span></div>
                <div :class="['year', 'center',{'selected': currTimeType == 'year'}]"
                     @click="this.currTimeType = 'year'"><span>月</span></div>
            </div>
        </div>
        <div class="time-list">
            <div class="times between">
                <span class="iconfont icon-xiangzuo1" @click="prevTime"></span>
                <span v-text="sleepType == 'week' ? sleepWeek : sleepMon"></span>
                <span class="iconfont icon-xiangyou1" @click="nextTime"></span>
            </div>
        </div>
    </div>
    <div class="echarts-box" v-show="viewState == 2">
        <div style="height: 100%">
            <div class="echarts-top">
                <div class="echarts-top-list">
                    <div class="list-1 border-right">
                        <div class="list-font-1 orange">
                            <span class="font-hold">1</span>
                            <span>时</span>
                            <span class="font-hold">5</span>
                            <span>分</span>
                        </div>
                        <div class="list-font-2"><span>平均深睡时间</span></div>
                    </div>
                    <div class="list-2 border-right">
                        <div class="list-font-1 blue-2">
                            <span class="font-hold">7</span>
                            <span>时</span>
                            <span class="font-hold">15</span>
                            <span>分</span>
                        </div>
                        <div class="list-font-2"><span>平均睡眠时间</span></div>
                    </div>
                    <div class="list-3">
                        <div class="list-font-1 blue-2">
                            <span class="font-hold">2</span>
                            <span>次</span>
                        </div>
                        <div class="list-font-2"><span>平均清醒次数</span></div>
                    </div>
                </div>
                <div class="echarts-top-list-2">
                    <div class="list-1 border-right">
                        <div class="list-font-1 orange">
                            <span class="font-hold">12:30</span>
                        </div>
                        <div class="list-font-2"><span>平均入睡时间</span></div>
                    </div>
                    <div class="list-2 border-right">
                        <div class="list-font-1 blue-2">
                            <span class="font-hold">7</span>
                            <span>次</span>
                        </div>
                        <div class="list-font-2 blue-2"><span>使用睡眠仪次数</span></div>
                    </div>
                    <div class="list-3">
                        <div class="list-font-1 blue-2">
                            <span class="font-hold">07:00</span>
                        </div>
                        <div class="list-font-2"><span>平均醒来时间</span></div>
                    </div>
                </div>
            </div>
            <div class="top" style="margin-top: 30px">
                <div class="echarts-list">
                    <div class="echarts-left">睡眠时长</div>
                    <div class="echarts-right" ref="sleepTime1"></div>
                </div>
                <div class="echarts-list">
                    <div class="echarts-left">深睡时间</div>
                    <div class="echarts-right" ref="sleepTime2"></div>
                </div>
                <div class="echarts-list">
                    <div class="echarts-left">清醒次数</div>
                    <div class="echarts-right" ref="sleepTime3"></div>
                </div>
                <div class="echarts-down" @click="this.show = 'top2'">
                    <span class="iconfont icon-jiantouxia"></span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: "SleepReport",
    data() {
        return {
            currTimeType: 'mon', // 月mon 年year
            sleepType: 'week', // 周week  月mon
            show: 'top', // top显示上部 bottom下部
            monTimes: '2021-12',
            yearTimes: '2021',
            token: '',
            data: [],
            btnState: false,
            viewState: 1, // 1 使用日记 2 睡眠监测
            currMonthTime: '',
            currYearTime: '',
        }
    },
    watch: {
        currTimeType() {
            this.getData()
        },
        show() {
            this.changeData()
        },
        data() {
            this.changeData();
        }
    },
    methods: {
        onChangeView(value) {
            this.viewState = value;
        },
        changeData() {
            if (this.show == 'top') {
                this.$nextTick(() => {
                    this.createEcharts(this.$refs.sleep1, this.data[0])
                    this.createEcharts(this.$refs.sleep2, this.data[1])
                    this.createEcharts(this.$refs.sleep3, this.data[2])
                    this.createEcharts(this.$refs.sleep4, this.data[3])
                    this.createEcharts(this.$refs.sleep5, this.data[4])
                    this.createEcharts(this.$refs.sleep6, this.data[5])
                    this.createEcharts(this.$refs.sleep7, this.data[6])
                    this.createEcharts(this.$refs.sleep8, this.data[7])
                    this.createEcharts(this.$refs.sleep9, this.data[8])
                })
            }
        },
        onChangeBtn() {
            this.btnState = this.btnState ? false : true;
            this.$axios.post('/api/changeDiary', {
                token: this.token,
                state: this.btnState
            }).then(() => {
                this.getData();
            })
        },
        prevTime() {
            if (this.currTimeType == 'mon') {
                let time = this.monTimes.split('-');
                let year = time[0];
                let mon = parseInt(parseInt(time[1]) - 1);
                if (mon < 1) {
                    mon = '12';
                    year = parseInt(year) - 1;
                }
                if (mon <= 9) {
                    mon = '0' + mon;
                }
                this.monTimes = year + '-' + mon;
            } else {
                this.yearTimes = parseInt(this.yearTimes) - 1;
            }
            this.getData()
        },
        nextTime() {
            if (this.currTimeType == 'mon') {
                let time = this.monTimes.split('-');
                let year = time[0];
                let mon = parseInt(parseInt(time[1]) + 1);
                if (mon > 12) {
                    mon = '1';
                    year = parseInt(year) + 1;
                }
                if (mon <= 9) {
                    mon = '0' + mon;
                }
                this.monTimes = year + '-' + mon;
            } else {
                this.yearTimes = parseInt(this.yearTimes) + 1;
            }
            this.getData()
        },
        createEcharts(dom, data) {
            let myEchart = echarts.init(dom);
            let xData = this.currTimeType == 'mon' ?
                ['第1周', '第2周', '第3周', '第4周'] :
                ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
            let max = this.currTimeType == 'mon' ? 6 : 30;
            let min = this.currTimeType == 'mon' ? 0 : 0;
            let interval = this.currTimeType == 'mon' ? 2 : 10;
            let option = {
                grid: {
                    left: '0',
                    top: '15px',
                    right: '0',
                    bottom: '5px',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    boundaryGap: true,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: 'rgba(153, 153, 153, 1)',
                        align: 'center',
                        fontSize: 10,
                        textStyle: {
                            color: 'rgb(153, 153, 153)'
                        }
                    },
                },
                yAxis: {
                    // show: false,
                    splitLine: {
                        lineStyle: {
                            color: 'rgb(213, 213, 213)'
                        }
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: 'rgba(153, 153, 153, 1)',
                        align: 'right',
                        fontSize: 8,
                        margin: 10,
                        textStyle: {
                            color: 'rgb(153, 153, 153)'
                        }
                    },
                    type: 'value',
                    max: max,
                    min: min,
                    interval: interval,
                    position: 'right',
                },
                series: [
                    {
                        data: data,
                        type: 'bar',
                        barWidth: 5,
                        silent: true,
                        itemStyle: {
                            color: 'rgb(10, 88, 245)',
                            normal: {
                                //柱形图圆角，初始化效果
                                barBorderRadius: [5, 5, 0, 0]
                            }
                        },
                    }
                ]
            };
            myEchart.setOption(option, true);
        },
        async getData() {
            this.show = 'top';
            this.$axios.get('/api/healthkit/readSleep?time=' + this.sleepTime + '&type=' + this.sleepType, {
                headers: {'token': this.token},
            }).then((response) => {
                let data = response.data;
                this.data = data.data;
            })
        },
        async getIsDiary() {
            this.$axios.get('/api/getIsDiary?token=' + this.token).then((response) => {
                let data = response.data;
                this.btnState = data.data == 1 ? true : false;
                if (this.btnState) this.getData();
            })
        },
        getUrlKey: function (name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)')
                .exec(location.href) || ['', ""])[1].replace(/\+/g, '%20')) || null
        },
        currTimes() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month <= 9) {
                month = '0' + month;
            }
            this.currMonthTime = year + '-' + month;
            this.monTimes = year + '-' + month;
            this.currYearTime = year;
            this.yearTimes = year;
        }
    },
    mounted() {
        this.getIsDiary();
        this.currTimes();
    },
    created() {
        this.token = this.getUrlKey('token');
    },
}
</script>

<style scoped>
.header {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(242, 243, 245);
    border-bottom: 1px solid rgb(221, 222, 224);
    z-index: 2;
}

.header img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    object-fit: contain;
}

.header span {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 16px;
    font-weight: 500;
}

.content {
    padding-top: 56px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: rgb(242, 243, 245);
}

.content-top div {
    width: 50%;
    height: 100%;
    line-height: 48px;
    position: relative;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected {
    color: rgb(10, 88, 245);
}

.selected:after {
    width: 30px;
    height: 1px;
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 10px;
    background-color: rgb(10, 88, 245);
}

.time-box {
    width: 100%;
    height: 32px;
}

.time-box .time-list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.year-mon {
    width: 130px;
    height: 30px;
    border-radius: 30px;
    color: rgb(99, 100, 102);
}

.year-mon div {
    width: 50px;
    height: 100%;
    border-radius: 30px;
    font-size: 16px;
    line-height: 30px;
    position: relative;
}

.times {
    width: 130px;
    height: 30px;
    color: rgb(99, 100, 102);
    font-size: 14px;
}

.times .iconfont {
    color: rgb(193, 194, 196);
}

.between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 30px;
}

.echarts-box {
    width: 100%;
    height: calc(100% - 65px);
    padding: 0px 16px 0 16px;
    margin-top: 32px;
    box-sizing: border-box;
    overflow: auto;
    background-color: rgb(242, 243, 245);
}

.echarts-list {
    width: 100%;
    height: 88px;
    background-color: rgb(255, 255, 255);
    border-radius: 16px;
    margin-bottom: 16px;
    padding: 0 16px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    /*justify-content: center;*/
    align-items: center;
}

.echarts-list-2 {
    width: 100%;
    height: 44px;
    margin-bottom: 16px;
    font-size: 14px;
    color: rgb(102, 102, 102);
}

.echarts-left {
    width: 70%;
    height: 100%;
}

.echarts-right {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    float: left;
    color: rgb(102, 102, 102);
}

.echarts-right div {
    width: 100% !important;
}

/*三级文本、提示性文本颜色反色*/

.text-scale-20to14 {
    font-size: 20px;
    text-overflow: ellipsis;
}

.top {
    position: relative;
}

.echarts-down {
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    color: rgba(232, 233, 238, 1);
    z-index: 2;
}

.icon-jiantoushang {
    margin-right: 16px;
}

.echarts-bottom-title {
    font-size: 18px;
    width: 58%;
    display: flex;
    flex-wrap: wrap;
}

.echarts-bottom-title div {
    width: 100%;
    display: flex;
    align-content: start;
    justify-content: left;
}

.bottom-switch {
    width: 50px;
    height: 25px;
    border-radius: 25px;
    position: absolute;
    right: 16px;
    padding: 0 2px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bottom-switch div {
    width: 21px;
    height: 21px;
    border-radius: 100%;
}

.echarts-top {
    width: 100%;
    height: 125px;
    border-radius: 16px;
    background-color: rgb(37, 40, 111);
    padding: 5px;
    box-sizing: border-box;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.3);
}

.echarts-top-list {
    height: 50%;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: start;
}

.echarts-top-list-2 {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: start;
}

.list-1 {
    width: 32%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.list-2 {
    width: 36%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.list-3 {
    width: 32%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.list-font-1 {
    height: 16px;
    width: 100%;
    line-height: 16px;
    margin-top: 5px;
}

.list-font-2 {
    height: 14px;
    width: 100%;
    line-height: 14px;
    margin-bottom: 10px;
}

.font-hold {
    font-weight: bolder;
    font-size: 16px;
}

.echarts-top-list .border-right::after {
    width: 1px;
    height: 50px;
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
}

.echarts-top-list-2 .border-right::after {
    width: 1px;
    height: 50px;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.3);
}

.btn {
    background-color: #fff;
}

.gray {
    background-color: rgb(203, 203, 203);
}

.blue {
    background-color: rgb(9, 87, 244);
}

.orange {
    color: rgb(235, 115, 33);
}

.blue-2 {
    color: rgb(73, 237, 74);;
}

.disable {
    cursor: not-allowed;
    pointer-events: none;
    color: rgb(242, 243, 245) !important;
}
</style>
